import React, { forwardRef, useEffect, useRef, useState } from "react";
import { intersperse } from "ramda";

import { PageSection, PageSectionTitle, ColorSelector } from "../styled";
import { H4, H6, SmallP, P } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { colors } from "shared/ui/colors";
import { BlueprintIcon } from "shared/ui/blueprint-icon";
import { ColorButton } from "shared/ui/color-button";
import ImageLoader from "shared/ui/image-loader";
import Columns from "shared/ui/blocks/columns";
import Tabs from "shared/ui/blocks/tabs";
import { Link } from "react-router-dom";
import { useTranslations } from "../../../../shared/context/translations";
import parseMarkdown from "../../../../shared/utils/markdown";
import { BUTTON_SIZES, BUTTON_VARIANTS, FakeButton } from "../../../../shared/ui/button";

const blueprints = ["1x1", "1x2", "2x2", "2x3", "3x3", "3x4"];
const boothColors = ["white", "naturalOak", "black"];

const linksMap = {
  "1x1": {
    white:
      "/ultra/product/1x1-configuration-c?color=White",
    naturalOak:
      "/ultra/product/1x1-configuration-c?color=Natural%20Oak",
    black:
      "/ultra/product/1x1-configuration-c?color=Black",
  },
  "1x2": {
    white:
      "/ultra/product/1x2-configuration-c?color=White",
    naturalOak:
      "/ultra/product/1x2-configuration-c?color=Natural%20Oak",
    black:
      "/ultra/product/1x2-configuration-c?color=Black",
  },
  "2x2": {
    white:
      "/ultra/product/2x2-configuration-c?color=White",
    naturalOak:
      "/ultra/product/2x2-configuration-c?color=Natural%20Oak",
    black:
      "/ultra/product/2x2-configuration-c?color=Black",
  },
  "2x3": {
    white:
      "/ultra/product/2x3-configuration-c?color=White",
    naturalOak:
      "/ultra/product/2x3-configuration-c?color=Natural%20Oak",
    black:
      "/ultra/product/2x3-configuration-c?color=Black",
  },
  "3x3": {
    white:
      "/ultra/product/3x3-configuration-c?color=White",
    naturalOak:
      "/ultra/product/3x3-configuration-c?color=Natural%20Oak",
    black:
      "/ultra/product/3x3-configuration-c?color=Black",
  },
  "3x4": {
    white:
      "/ultra/product/3x4-configuration-c?color=White",
    naturalOak:
      "/ultra/product/3x4-configuration-c?color=Natural%20Oak",
    black:
      "/ultra/product/3x4-configuration-c?color=Black",
  },
};

const Intro = (props, ref) => {
  const [blueprint, setBlueprint] = useState(2);
  const [color, setColor] = useState(0);
  const currentBlueprint = blueprints[blueprint];
  const currentColor = boothColors[color];
  const image = `/images/content/hp/mini-config/ultra/${blueprints[blueprint]}_${boothColors[color]}.jpg`;
  const t = useTranslations('homepageSections.ultra.intro')
  const lookCloserT = useTranslations('homepageSections.ultra.lookCloser')

  return (
    <PageSection ref={ref}>
      <PageSectionTitle>{t('name')}</PageSectionTitle>
      <BlueprintIcon color={colors.primary} />
      <Gap />
      <Tabs active={blueprint} setActive={setBlueprint}>
        {blueprints.map((bp) => (
          <P $bold key={bp}>
            {bp}
          </P>
        ))}
      </Tabs>
      <Columns $reverseOnMobile $mobileBreakpoint={767}>
        <>
          <H4 $accent $bold>
            {t('title')}
          </H4>
          <Gap />
          <H6 $light>
            {t('subTitle')}
          </H6>
          <Gap />
          <SmallP as="div" dangerouslySetInnerHTML={{ __html: parseMarkdown(t('description')) }} />
          <Gap />
          <P $bold>{t('certificationsTitle')}</P>
          <Gap />
          {lookCloserT('seals').map(seal => (
            <a href={seal.link} target="_blank">
              <img src={seal.image.src} alt={seal.image.title} />
            </a>
          ))}
        </>
        <Link to={linksMap[currentBlueprint][currentColor]} style={{ position: 'relative' }}>
          <ImageLoader ratio={"4:3"} source={image} />
          {t('button.text') && (
            <FakeButton $withHover size={BUTTON_SIZES.TINY} variant={BUTTON_VARIANTS.ACCENT_FILLED} style={{ position: 'absolute', left: 0, bottom: 0, }}>
              {t('button.text')}
            </FakeButton>
          )}
        </Link>
      </Columns>
      <ColorSelector>
        {intersperse(
          <Gap />,
          boothColors.map((c, index) => (
            <ColorButton
              key={c}
              active={color === index}
              onClick={() => setColor(index)}
              texture={colors[c]}
            />
          ))
        )}
      </ColorSelector>
    </PageSection>
  );
};

export default forwardRef(Intro);
