import styled, { css } from "styled-components";
import { propTernary } from "../utils/styled";
import { colors } from "./colors";

export const BUTTON_VARIANTS = {
  PRIMARY: "primary",
  ACCENT: "accent",
  SECONDARY: "secondary",
  OUTLINE: "outline",
  FILLED: "filled",
  INLINE: "inline",
  INFO: "info",
  INFO_OUTLINED: "info_outlined",
  ACCENT_FILLED: "accent_filled",
  BLACK_FILLED: "black_filled",
  ACCENT_OUTLINE: "accent_outline",
};

export const BUTTON_SIZES = {
  NORMAL: "normal",
  SMALL: "small",
  TINY: "tiny",
  INLINE: "inline",
};

const buildStylesForVariant = (bgColor, fgColor, border = false) => `
  -webkit-text-fill-color: initial;
	background-color: ${bgColor};
  color: ${fgColor};
  ${border ? `border: 2px solid ${border};` : ""}
`;

const buildStylesForSize = (
  fontSize,
  padding,
  letterSpacing,
  fontWeight,
  mobileFontSize,
  mobilePadding,
  lineHeight = '26px',
) => `
	font-size: ${fontSize};
  padding: ${padding};
  letter-spacing: ${letterSpacing};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  @media screen and (max-width: 991px) {
    font-size: ${mobileFontSize};
    padding: ${mobilePadding};
  }
`;

const stylesForVariant = ({ variant }) => {
  switch (variant) {
    case BUTTON_VARIANTS.ACCENT: {
      return buildStylesForVariant(colors.white, colors.accent);
    }
    case BUTTON_VARIANTS.ACCENT_FILLED: {
      return buildStylesForVariant(colors.accent, colors.white, colors.accent);
    }
    case BUTTON_VARIANTS.BLACK_FILLED: {
      return buildStylesForVariant(colors.black, colors.white, colors.nickelGray);
    }
    case BUTTON_VARIANTS.ACCENT_OUTLINE: {
      return buildStylesForVariant(colors.transparent, colors.accent, colors.accent);
    }
    default:
    case BUTTON_VARIANTS.PRIMARY: {
      return buildStylesForVariant(colors.transparent, colors.black);
    }
    case BUTTON_VARIANTS.SECONDARY: {
      return buildStylesForVariant(colors.white, colors.black);
    }
    case BUTTON_VARIANTS.OUTLINE: {
      return buildStylesForVariant(colors.transparent, colors.white);
    }
    case BUTTON_VARIANTS.FILLED: {
      return buildStylesForVariant(colors.white, colors.black, colors.white);
    }
    case BUTTON_VARIANTS.INFO: {
      return buildStylesForVariant(colors.nickelGray, colors.white, colors.nickelGray);
    }
    case BUTTON_VARIANTS.INFO_OUTLINED: {
      return buildStylesForVariant(colors.transparent, colors.nickelGray, colors.nickelGray);
    }
    case BUTTON_VARIANTS.INLINE: {
      return buildStylesForVariant(colors.transparent, colors.primary, colors.transparent);
    }
  }
};

const stylesForSize = ({ size }) => {
  switch (size) {
    default:
    case BUTTON_SIZES.NORMAL: {
      return buildStylesForSize(
        "12px",
        "10px 20px",
        "1.44px",
        "600",
        "12px",
        "6px 12px"
      );
    }
    case BUTTON_SIZES.SMALL: {
      return buildStylesForSize(
        "12px",
        "4px 12px",
        "0.44px",
        "600",
        "12px",
        "4px 8px"
      );
    }
    case BUTTON_SIZES.TINY: {
      return buildStylesForSize(
        "11px",
        "4px 8px",
        "0.33px",
        "600",
        "11px",
        "4px 8px",
        "15px"
      );
    }
    case BUTTON_SIZES.INLINE: {
      return buildStylesForSize(
        "12px",
        "4px 0",
        "0.44px",
        "300",
        "12px",
        "4px 0"
      );
    }
  }
};

export const PlainButton = styled.button`
  all: unset;
  box-sizing: border-box;
`;

const buttonStyles = css`
  display: inline-block;
  box-sizing: border-box;
  border: 2px solid currentColor;
  ${stylesForVariant}
  ${stylesForSize}
  text-transform: ${propTernary('$normalCase', 'capitalize', 'uppercase')};
  text-align: center;
  text-decoration: none;
  border-radius: 27px;
  transition: all 0.2s ease-out;
  opacity: ${propTernary("$hide", "0 !important", 1)};
  white-space: ${propTernary("$noWrap", "nowrap", "normal")};
  cursor: pointer;
  width: ${({ $wide }) => ($wide ? "100%" : "auto")};
  ${({ $withHover }) =>
    $withHover
      ? css`
          margin-top: 4px;
          &:hover {
            transform: translateY(-4px);
          }
        `
      : ""}
  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }
  @media screen and (max-width: 991px) {
    border-radius: 20px;
  }
`;

export const Button = styled(PlainButton)`
  ${buttonStyles}
`;

export const FakeButton = styled.div`
  ${buttonStyles}
`;
