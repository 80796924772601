import { getCurrencyCookie } from "../cookies";
import { generateTable } from "./create-table";
import { generateConfigUrl } from "./helpers";
const pdf = require("pdfjs");
const Helvetica = require("pdfjs/font/Helvetica");
const HelveticaBold = require("pdfjs/font/Helvetica-Bold");
const dataUriToBuffer = require("data-uri-to-buffer");

export function download(filename, url) {
  const element = document.createElement("a");
  element.setAttribute("href", url);
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export const getImageFromCanvas = () => {
  const canvas = document.getElementsByTagName("canvas")[0];
  if (canvas) {
    return canvas.toDataURL("image/jpeg", 1.0);
  }
};

export const getDataUrl = img => {
  // Create canvas
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  // Set width and height
  canvas.width = img.width;
  canvas.height = img.height;
  // Draw the image
  ctx.drawImage(img, 0, 0, img.width, img.height);
  return canvas.toDataURL('image/jpeg', 1.0);
}

export const generatePDF = (data, state, currentView, siteArea, t) => {
  const doc = new pdf.Document({ font: Helvetica });
  const currency = getCurrencyCookie();

  var header = doc
    .header()
    .table({ widths: [null, null], paddingBottom: 1 * pdf.cm })
    .row();

  const url = generateConfigUrl(state);
  header
    .cell({ paddingBottom: 1 * pdf.cm })
    .text(t(`pdf.${siteArea}.title`), {
      fontSize: 28,
      font: HelveticaBold,
    })
    .text(t('pdf.link.text'), {
      paddingTop: 0.5 * pdf.cm,
      fontSize: 12,
      font: Helvetica,
      link: url,
      underline: true,
      color: 0x000000,
    })
  if (currentView !== 'no-image') {
    const imageTable = doc.table({
      widths: [150, null, 150],
    });

    const imageTableRow = imageTable.row();
    imageTableRow.cell("");

    const img = new pdf.Image(dataUriToBuffer(
      currentView === '3d'
        ? getImageFromCanvas()
        : getDataUrl(document.getElementById('preview-image'))
    ));
    imageTableRow.cell({ paddingBottom: 1 * pdf.cm }).image(img);
    imageTableRow.cell("");
  }
  generateTable(data, state, doc, t);

  doc
    .cell({ paddingTop: pdf.cm })
    .text(t('pdf.footnote').replace('{{currency}}', currency));
  const today = new Date()
  doc
    .cell({ paddingTop: 2 * pdf.cm })
    .text(`${today.getUTCDate()}-${today.getUTCMonth() + 1}-${today.getUTCFullYear()}`, { textAlign: "right" });
  doc
    .cell({ paddingTop: 2 * pdf.cm })
    .text(t('pdf.info'), { textAlign: "right" });
  doc.text("sales@vicoustic.com", {
    link: url,
    underline: true,
    color: 0x000000,
    textAlign: "right",
  });

  doc.asBuffer().then((docData) => {
    const blob = new Blob([docData], { type: "application/pdf" });
    download("configuration.pdf", URL.createObjectURL(blob));
  });
};
