import React, { forwardRef, useEffect, useRef, useState } from "react";
import { intersperse } from "ramda";

import { PageSection, PageSectionTitle, ColorSelector } from "../styled";
import { H4, H6, SmallP, P } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { colors } from "shared/ui/colors";
import { BlueprintIcon } from "shared/ui/blueprint-icon";
import { ColorButton } from "shared/ui/color-button";
import ImageLoader from "shared/ui/image-loader";
import Columns from "shared/ui/blocks/columns";
import Tabs from "shared/ui/blocks/tabs";
import { Link } from "react-router-dom";
import { useTranslations } from "../../../../shared/context/translations";
import parseMarkdown from '../../../../shared/utils/markdown';
import { useSiteAreaContext } from "../../../../shared/context/site-area";
import { BUTTON_SIZES, BUTTON_VARIANTS, FakeButton } from "../../../../shared/ui/button";

const blueprints = ["1x1", "1x2", "1x3", "2x2", "2x3", "3x3", "3x4"];
const boothColors = ["white", "naturalOak", "black"];

const linksMap = {
  "1x1": {
    white:
      "/office/product/phone-booth-23?color=White&region=EU",
    naturalOak:
      "/office/product/phone-booth-23?color=Natural%20Oak&region=EU",
    black:
      "/office/product/phone-booth-23?color=Black&region=EU",
  },
  "1x2": {
    white:
      "/office/configurator?blueprint=vbo23-1x2&blueprintRegion=NONE&panel1_color=white&panel1_type=vbo23-door-w-window&panel2_color=white&panel2_type=vbo23-standard-wall&panel3_color=white&panel3_type=vbo23-standard-wall&panel4_color=white&panel4_type=vbo23-standard-wall&panel5_color=white&panel5_type=vbo23-standard-wall&panel6_color=white&panel6_type=vbo23-side-w-large-window&ventilatorType=NONE",
    naturalOak:
      "/office/configurator?blueprint=vbo23-1x2&blueprintRegion=NONE&panel1_color=natural-oak&panel1_type=vbo23-door-w-window&panel2_color=natural-oak&panel2_type=vbo23-standard-wall&panel3_color=natural-oak&panel3_type=vbo23-standard-wall&panel4_color=natural-oak&panel4_type=vbo23-standard-wall&panel5_color=natural-oak&panel5_type=vbo23-standard-wall&panel6_color=natural-oak&panel6_type=vbo23-side-w-large-window&ventilatorType=NONE",
    black:
      "/office/configurator?blueprint=vbo23-1x2&blueprintRegion=NONE&panel1_color=black&panel1_type=vbo23-door-w-window&panel2_color=black&panel2_type=vbo23-standard-wall&panel3_color=black&panel3_type=vbo23-standard-wall&panel4_color=black&panel4_type=vbo23-standard-wall&panel5_color=black&panel5_type=vbo23-standard-wall&panel6_color=black&panel6_type=vbo23-side-w-large-window&ventilatorType=NONE",
  },
  "1x3": {
    white:
      "/office/product/chat-booth-23?color=White&region=EU",
    naturalOak:
      "/office/product/chat-booth-23?color=Natural%20Oak&region=EU",
    black:
      "/office/product/chat-booth-23?color=Black&region=EU",
  },
  "2x2": {
    white:
      "/office/product/focus-cube-23?color=White&region=EU",
    naturalOak:
      "/office/product/focus-cube-23?color=Natural%20Oak&region=EU",
    black:
      "/office/product/focus-cube-23?color=Black&region=EU",
  },
  "2x3": {
    white:
      "/office/configurator?blueprint=vbo23-2x3&blueprintRegion=NONE&panel10_color=white&panel10_type=vbo23-side-w-large-window&panel1_color=white&panel1_type=vbo23-door-w-window&panel2_color=white&panel2_type=vbo23-standard-wall&panel3_color=white&panel3_type=vbo23-standard-wall&panel4_color=white&panel4_type=vbo23-standard-wall&panel5_color=white&panel5_type=vbo23-standard-wall&panel6_color=white&panel6_type=vbo23-standard-wall&panel7_color=white&panel7_type=vbo23-side-w-window&panel8_color=white&panel8_type=vbo23-side-w-window&panel9_color=white&panel9_type=vbo23-side-w-large-window&ventilatorType=NONE",
    naturalOak:
      "/office/configurator?blueprint=vbo23-2x3&blueprintRegion=NONE&panel10_color=natural-oak&panel10_type=vbo23-side-w-large-window&panel1_color=natural-oak&panel1_type=vbo23-door-w-window&panel2_color=natural-oak&panel2_type=vbo23-standard-wall&panel3_color=natural-oak&panel3_type=vbo23-standard-wall&panel4_color=natural-oak&panel4_type=vbo23-standard-wall&panel5_color=natural-oak&panel5_type=vbo23-standard-wall&panel6_color=natural-oak&panel6_type=vbo23-standard-wall&panel7_color=natural-oak&panel7_type=vbo23-side-w-window&panel8_color=natural-oak&panel8_type=vbo23-side-w-window&panel9_color=natural-oak&panel9_type=vbo23-side-w-large-window&ventilatorType=NONE",
    black:
      "/office/configurator?blueprint=vbo23-2x3&blueprintRegion=NONE&panel10_color=black&panel10_type=vbo23-side-w-large-window&panel1_color=black&panel1_type=vbo23-door-w-window&panel2_color=black&panel2_type=vbo23-standard-wall&panel3_color=black&panel3_type=vbo23-standard-wall&panel4_color=black&panel4_type=vbo23-standard-wall&panel5_color=black&panel5_type=vbo23-standard-wall&panel6_color=black&panel6_type=vbo23-standard-wall&panel7_color=black&panel7_type=vbo23-side-w-window&panel8_color=black&panel8_type=vbo23-side-w-window&panel9_color=black&panel9_type=vbo23-side-w-large-window&ventilatorType=NONE",
  },
  "3x3": {
    white:
      "/office/configurator?blueprint=vbo23-3x3&blueprintRegion=NONE&panel10_color=white&panel10_type=vbo23-side-w-window&panel11_color=white&panel11_type=vbo23-side-w-large-window&panel12_color=white&panel12_type=vbo23-side-w-large-window&panel1_color=white&panel1_type=vbo23-door-w-window&panel2_color=white&panel2_type=vbo23-standard-wall&panel3_color=white&panel3_type=vbo23-standard-wall&panel4_color=white&panel4_type=vbo23-standard-wall&panel5_color=white&panel5_type=vbo23-standard-wall&panel6_color=white&panel6_type=vbo23-standard-wall&panel7_color=white&panel7_type=vbo23-standard-wall&panel8_color=white&panel8_type=vbo23-side-w-window&panel9_color=white&panel9_type=vbo23-side-w-window&ventilatorType=NONE",
    naturalOak:
      "/office/configurator?blueprint=vbo23-3x3&blueprintRegion=NONE&panel10_color=natural-oak&panel10_type=vbo23-side-w-window&panel11_color=natural-oak&panel11_type=vbo23-side-w-large-window&panel12_color=natural-oak&panel12_type=vbo23-side-w-large-window&panel1_color=natural-oak&panel1_type=vbo23-door-w-window&panel2_color=natural-oak&panel2_type=vbo23-standard-wall&panel3_color=natural-oak&panel3_type=vbo23-standard-wall&panel4_color=natural-oak&panel4_type=vbo23-standard-wall&panel5_color=natural-oak&panel5_type=vbo23-standard-wall&panel6_color=natural-oak&panel6_type=vbo23-standard-wall&panel7_color=natural-oak&panel7_type=vbo23-standard-wall&panel8_color=natural-oak&panel8_type=vbo23-side-w-window&panel9_color=natural-oak&panel9_type=vbo23-side-w-window&ventilatorType=NONE",
    black:
      "/office/configurator?blueprint=vbo23-3x3&blueprintRegion=NONE&panel10_color=black&panel10_type=vbo23-side-w-window&panel11_color=black&panel11_type=vbo23-side-w-large-window&panel12_color=black&panel12_type=vbo23-side-w-large-window&panel1_color=black&panel1_type=vbo23-door-w-window&panel2_color=black&panel2_type=vbo23-standard-wall&panel3_color=black&panel3_type=vbo23-standard-wall&panel4_color=black&panel4_type=vbo23-standard-wall&panel5_color=black&panel5_type=vbo23-standard-wall&panel6_color=black&panel6_type=vbo23-standard-wall&panel7_color=black&panel7_type=vbo23-standard-wall&panel8_color=black&panel8_type=vbo23-side-w-window&panel9_color=black&panel9_type=vbo23-side-w-window&ventilatorType=NONE",
  },
  "3x4": {
    white:
      "/office/product/conference-booth-23?color=White&region=EU",
    naturalOak:
      "/office/product/conference-booth-23?color=Natural%20Oak&region=EU",
    black:
      "/office/product/conference-booth-23?color=Black&region=EU",
  },
};

const Intro = (props, ref) => {
  const { siteArea } = useSiteAreaContext()
  const [blueprint, setBlueprint] = useState(2);
  const [color, setColor] = useState(0);
  const currentBlueprint = blueprints[blueprint];
  const currentColor = boothColors[color];
  const image = `/images/content/hp/mini-config/office/${blueprints[blueprint]}_${boothColors[color]}.jpg`;
  const t = useTranslations(`homepageSections.${siteArea}.intro`)
  const lookCloserT = useTranslations(`homepageSections.${siteArea}.lookCloser`)
  return (
    <PageSection $siteArea={siteArea} ref={ref}>
      <PageSectionTitle>{t('name')}</PageSectionTitle>
      <BlueprintIcon color={colors.primary} />
      <Gap />
      <Tabs active={blueprint} setActive={setBlueprint}>
        {blueprints.map((bp) => (
          <P $bold key={bp}>
            {bp}
          </P>
        ))}
      </Tabs>
      <Columns $reverseOnMobile $mobileBreakpoint={767}>
        <>
          <H4 $accent $bold>
            {t('title')}
          </H4>
          <Gap />
          <H6 $light>
            {t('subTitle')}
          </H6>
          <Gap />
          <SmallP as="div" dangerouslySetInnerHTML={{ __html: parseMarkdown(t('description')) }} />
          <Gap />
          <P $bold>{t('certificationsTitle')}</P>
          <Gap />
          {lookCloserT('seals').map(seal => (
            <a href={seal.link} target="_blank">
              <img src={seal.image.src} alt={seal.image.title} />
            </a>
          ))}
        </>
        <Link to={linksMap[currentBlueprint][currentColor]} style={{ position: 'relative' }}>
          <ImageLoader ratio={"4:3"} source={image} />
          {t('button.text') && (
            <FakeButton $withHover size={BUTTON_SIZES.TINY} variant={BUTTON_VARIANTS.ACCENT_FILLED} style={{ position: 'absolute', left: 0, bottom: 0, }}>
              {t('button.text')}
            </FakeButton>
          )}
        </Link>
      </Columns>
      <ColorSelector>
        {intersperse(
          <Gap />,
          boothColors.map((c, index) => (
            <ColorButton
              key={c}
              active={color === index}
              onClick={() => setColor(index)}
              texture={colors[c]}
            />
          ))
        )}
      </ColorSelector>
    </PageSection>
  );
};

export default forwardRef(Intro);
