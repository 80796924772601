import React, { useEffect } from 'react'
import { siteAreas } from '../../../backoffice/helpers'
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from '../../../shared/ui/button'
import { FilterButton, FilterButtonList } from './styled'
import { setUrlVars } from '../../../shared/utils/url'

const AreaFilterPanel = props => {
  const { siteArea, setSiteArea, setFilters } = props
  useEffect(() => {
    setUrlVars({
      area: !!siteArea ? siteArea : null,
    })
  }, [siteArea, setFilters])
  
  return (
    <FilterButtonList>
      {siteAreas.filter(area => !area.id.includes('-na')).map(area => (
        <FilterButton key={area.id} $active={siteArea === area.id}>
          <Button
            $normalCase
            $noWrap
            variant={BUTTON_VARIANTS.INFO_OUTLINED}
            size={BUTTON_SIZES.SMALL}
            onClick={() => {
              setFilters([])
              setSiteArea(siteArea === area.id ? '' : area.id)
            }}
          >{area.displayName}</Button>
        </FilterButton>
      ))}
    </FilterButtonList>
  )
}

export default AreaFilterPanel
