import React from 'react'
import { Helmet } from 'react-helmet'
import { useData } from '../../../shared/hooks/use-data'
import { useRenderedOnClient } from '../../../shared/hooks/use-rendered-on-client'
import { Loading } from '../../../shared/ui/loading'
import { getUrlVars } from '../../../shared/utils/url'
import SimplifiedFooter from '../../partials/footer/simplified'
import NoContextHeader from '../../partials/header/no-context-header'
import ProductList from './product-list'

const ProductListPage = props => (
  <ProductList {...props} />
)

const useInitialFiltersFromUrl = (clientSide) => {
  if (!clientSide) return {}
  const urlVars = getUrlVars()
  return {
    filters: urlVars.filters?.split(',') || [],
    area: urlVars.area
  }
}

const GenericProductListContainer = props => {
  const isRenderedOnClient = useRenderedOnClient();
  const initialFiltersFromUrl = useInitialFiltersFromUrl(isRenderedOnClient);
  
  const { state, result, error } =  useData(`/api/products`)
  const isLoading = state === 'PENDING'

  return (
    <>
      <Helmet>
        <title>Products</title>
      </Helmet>
      <NoContextHeader />
      {isLoading
        ? <div><Loading /></div>
        : error || <ProductListPage
          showSiteAreaFilters
          filterSiteArea={initialFiltersFromUrl.area}
          urlPath="shop/product"
          initialFiltersFromUrl={initialFiltersFromUrl.filters}
          products={result}
        />
      }
      <SimplifiedFooter />
    </>
  )
}

export default GenericProductListContainer
