import { countryList as vicousticCountryList } from "../../vicoustic/utility/countries"
export const visibilities = [
  { id: "all", name: "Public" },
  { id: "admin", name: "Admin only" }
]

export const siteAreas = [											
  { id: "ultra", name: "Vicbooth Ultra", displayName: "Professional Audio" },
  { id: "office", name: "Vicbooth Office", displayName: "Office" },
  { id: "spa", name: "Vicbooth Spa", displayName: "Wellness" },
  { id: "gaming", name: "Vicbooth Gaming", displayName: "Gaming" },
  { id: "ultra-na", name: "Vicbooth Ultra USA/CA" },
  { id: "office-na", name: "Vicbooth Office USA/CA" },
  { id: "spa-na", name: "Vicbooth Spa USA/CA" },
  { id: "gaming-na", name: "Vicbooth Gaming USA/CA" },
]

export const articleCategories = [											
  ...siteAreas,
  { id: "awards", name: "Awards" },
]

export const countryList = vicousticCountryList.map(c => ({
  id: c.key,
  name: c.value,
}))
