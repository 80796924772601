import React from 'react'
import { Helmet } from 'react-helmet'
import { ModalManagerContextProvider } from "shared/modal-manager"
import { useData } from '../../../shared/hooks/use-data'
import { Loading } from '../../../shared/ui/loading'
import SimplifiedFooter from '../../partials/footer/simplified'
import NoContextHeader from '../../partials/header/no-context-header'
import { modalRegistry } from './modals'
import ProductDetail from './product-detail'

const ProductDetailPage = props => (
  <ModalManagerContextProvider modalRegistry={modalRegistry}>
    <ProductDetail {...props} />
  </ModalManagerContextProvider>
)

const GenericProductDetailContainer = props => {
  const { state, result, error } =  useData(`/api/products/${props.match.params.id}`)
  const isLoading = state === 'PENDING'

  return (
    <>
      <Helmet>
        {!isLoading && !error && <title>{result.name}</title>}
      </Helmet>
      <NoContextHeader />
      {isLoading
        ? <div><Loading /></div>
        : error || <ProductDetailPage hideConfigurator backLink="/shop/products" {...result} />}
      <SimplifiedFooter />
    </>
  )
}

export default GenericProductDetailContainer
