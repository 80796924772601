import { propOr } from 'ramda';
import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { useTranslations } from '../../../shared/context/translations';
import { BUTTON_SIZES, BUTTON_VARIANTS, FakeButton } from '../../../shared/ui/button';

const Root = styled.div`
  position: absolute;
  top: ${propOr('55px', '$top')};
  right: 30px;
  @media screen and (max-width: 768px) {
    top: 12px;
    position: relative;
    right: 0;
    padding-bottom: 15px;
  }
`

const ConfiguratorButton = props => {
  const t = useTranslations('shop')
  const { siteArea } = props

  const texts = {
    ultra: t('designYourOwnUltra'),
    office: t('designYourOwnOffice'),
    gaming: t('designYourOwnGaming'),
  }
  if (!texts[siteArea]) return null

  return (
    <Root $top={props.top}>
      <Link to={`/${siteArea}/configurator`}>
        <FakeButton
          variant={BUTTON_VARIANTS.BLACK_FILLED}
          size={BUTTON_SIZES.SMALL}
        >
          {texts[siteArea]}
        </FakeButton>
      </Link>
    </Root>
  )
}

export default ConfiguratorButton
