import React from 'react'
import { Helmet } from 'react-helmet'
import { useSiteAreaContext } from '../../../shared/context/site-area'
import { useData } from '../../../shared/hooks/use-data'
import { useRenderedOnClient } from '../../../shared/hooks/use-rendered-on-client'
import { Loading } from '../../../shared/ui/loading'
import { getUrlVars } from '../../../shared/utils/url'
import Footer from '../../partials/footer'
import Header from '../../partials/header'
import ProductList from './product-list'

const ProductListPage = props => (
  <ProductList {...props} />
)

const useInitialFiltersFromUrl = (clientSide) => {
  if (!clientSide) return []
  const urlVars = getUrlVars()
  return urlVars.filters?.split(',') || []
} 

const ProductListContainer = props => {
  const { filterSiteArea, siteArea } = useSiteAreaContext()
  const { state, result, error } =  useData(`/api/products?area=${filterSiteArea}`)
  const isLoading = state === 'PENDING'
  const isRenderedOnClient = useRenderedOnClient();

  const initialFiltersFromUrl = useInitialFiltersFromUrl(isRenderedOnClient);

  return (
    <>
      <Helmet>
        <title>Products</title>
      </Helmet>
      <Header />
      {isLoading
        ? <div><Loading /></div>
        : error || <ProductListPage siteArea={siteArea} hideConfigurator={props.hideConfigurator} initialFiltersFromUrl={initialFiltersFromUrl} products={result} />}
      <Footer />
    </>
  )
}

export default ProductListContainer
